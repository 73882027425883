import React, { ReactNode } from "react";
import { Typography } from "@mui/material";

type addrHeader = {
    headerLine: ReactNode;
};

export default function CheckAddress({ headerLine }: addrHeader) {
    return (
        <Typography variant="subtitle2" component="div">
            {headerLine}
            <br />
            University of Illinois Foundation
            <br />
            P.O. Box 734500
            <br />
            Chicago, IL 60673-4500
        </Typography>
    );
}
