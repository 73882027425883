import { Alert, Container } from "@mui/material";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { Button } from "@mui/material";
import { DevTool } from "@hookform/devtools";
import ContactInformation from "./ContactInformation";
import NameEmail from "./NameEmail";
import DepartmentCampus from "./DepartmentCampus";
import DonorInformation from "./DonorInformation";
import GiftTribute from "./GiftTribute";
import DesignationInformation from "./DesignationInformation";
import TransmittalTotals from "./TransmittalTotals";
import GiftInKindInformation from "./GiftInKindInformation";
import SubmitButton from "./SubmitButton";
import {
    FillCashCheckGIKForm,
    SharedFillForm,
    ShowFillButton,
} from "./HelperFunctions";
import { Col, Row } from "react-bootstrap";
import GiftInKindEmailMessage from "./GiftInKindEmailMessage";
import DeedOrGift from "./DeedOrGift";
import UrbanaAddress from "./UrbanaAddress";

type DataEntryParams = {
    onSubmit: any;
    showAnonymousDonorCheckBox: boolean;
    formType: string;
};

const CashCheckGIKDataEntry = ({
    onSubmit,
    showAnonymousDonorCheckBox,
    formType,
}: DataEntryParams) => {
    const { setValue, handleSubmit, control } = useFormContext();
    const [showBalanceProblemMessage, setShowBalanceProblemMessage] =
        useState(false);
    const fillButton = () => {
        FillCashCheckGIKForm(setValue);
        SharedFillForm(setValue);
    };

    return (
        <Container className="pb-4">
            <form onSubmit={handleSubmit(onSubmit)}>
                <DepartmentCampus formType={formType} />
                {ShowFillButton() ? (
                    <Button onClick={fillButton}>Fill</Button>
                ) : (
                    <></>
                )}

                <ContactInformation />
                <NameEmail />
                <DonorInformation
                    showAnonymousDonorCheckBox={showAnonymousDonorCheckBox}
                />
                {formType === "GiftInKind" && (
                    <GiftInKindInformation alwaysShowDeleteButtons={false} />
                )}
                <GiftTribute />
                <DesignationInformation formType={formType} />
                <TransmittalTotals
                    ignoreNonGiftChecked={false}
                    showGIKTotal={formType === "GiftInKind"}
                    setShowProblemMessage={setShowBalanceProblemMessage}
                    // formType={formType}
                />
                <Row className="submitRow">
                    <Col className="theSubmit col-2">
                        <SubmitButton />
                    </Col>
                    <Col>
                        {formType === "GiftInKind" ? (
                            <>
                                <GiftInKindEmailMessage formType={formType} />
                                <DeedOrGift formType={formType} />
                                <UrbanaAddress />
                            </>
                        ) : null}
                    </Col>
                </Row>
                <Row>
                    {showBalanceProblemMessage && (
                        <Row>
                            <Col className="displayContents">
                                <Alert severity="error">
                                    Total Amount must equal Gift in Kind Total
                                </Alert>
                            </Col>
                        </Row>
                    )}
                </Row>
            </form>
            <DevTool control={control} /> {/* set up the dev tool */}
        </Container>
    );
};

export default CashCheckGIKDataEntry;
