import { TableCell, TableRow } from "@mui/material";

type PrintParams = {
    oneSecurity: any;
    index: number;
};


const PrintOneSecWireSecurity = ({ oneSecurity, index }: PrintParams) => {
    return (
        <TableRow key={"security" + index}>
            <TableCell>
                {/* {formatAmount3(oneSecurity.shares)} */}
                {oneSecurity.shares}
            </TableCell>
            <TableCell>{oneSecurity.name}</TableCell>
            <TableCell>{oneSecurity.symbol}</TableCell>
        </TableRow>
    );
};
export default PrintOneSecWireSecurity;
