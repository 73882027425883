import { ReactNode } from "react";
import { Typography } from "@mui/material";

type addrHeader = {
    headerLine?: ReactNode;
};

export default function SpringfieldAddress({ headerLine = "" }: addrHeader) {
    return (
        <Typography variant="subtitle2" component="div" className="mb-2">
            {headerLine}
            University of Illinois Springfield
            <br />
            Business and Stewardship
            <br />
            One University Plaza - PAC591
            <br />
            Springfield, IL 62703
        </Typography>
    );
}
