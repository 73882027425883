// import { Helmet } from "react-helmet";
import { useForm, FormProvider } from "react-hook-form";
import InstructionsCash from "../components/InstructionsCash";
import { useNavigate } from "react-router-dom";

import CashCheckGIKDataEntry from "../components/CashCheckGIKDataEntry";

type CashParams = {
    formValues: any;
    setFormValues: Function;
};
export default function Cash({ formValues, setFormValues }: CashParams) {
    const methods = useForm({
        defaultValues: formValues,
        mode: "onChange",
    });

    const navigate = useNavigate();

    function removeEmptyFields(data: any) {}

    const onSubmit = (data: any) => {
        removeEmptyFields(data);
        setFormValues(data);
        navigate("/cashprint");
    };
    document.title = "U of I Foundation | Cash Transmittal Form";
    return (
        <>
            {/* <Helmet>
                <meta charSet="utf-8" />
                <title>U of I Foundation | Cash Transmittal Form</title>
                {/* <link rel="canonical" href="http://mysite.com/example" /> * /}
            </Helmet> */}
            <InstructionsCash />
            <FormProvider {...methods}>
                <CashCheckGIKDataEntry
                    onSubmit={onSubmit}
                    showAnonymousDonorCheckBox={true}
                    formType="Cash"
                />
            </FormProvider>
        </>
    );
}
