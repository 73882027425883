//import { Helmet } from "react-helmet";
import { useForm, FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import TrustDeferredDataEntry from "../components/TrustDeferredDataEntry";

type TrustParams = {
    formValues: any;
    setFormValues: Function;
};

export default function TrustDeferred({ formValues, setFormValues }: TrustParams) {
    const methods = useForm({
        defaultValues: formValues,
        mode: "onChange",
    });

    function removeEmptyFields(data: any) {}

    const navigate = useNavigate();

    const onSubmit = (data: any) => {
        removeEmptyFields(data);
        setFormValues(data);
        navigate("/trustdeferredprint");
    };
    document.title = "U of I Foundation | Trust Deferred Transmittal Form";
    return (
        <>
            {/* <Helmet>
                <meta charSet="utf-8" />
                <title>
                    U of I Foundation | Trust Deferred Transmittal Form{" "}
                </title>
                {/* <link rel="canonical" href="http://mysite.com/example" /> * /}
            </Helmet> */}
            {/* <InstructionsSecWire /> */}
            <FormProvider {...methods}>
                <TrustDeferredDataEntry
                    onSubmit={onSubmit}
                    showAnonymousDonorCheckBox={false}
                />
            </FormProvider>
        </>
    );
}
