type deedOrGiftProps = {
    formType: string;
};

export default function DeedOrGift({ formType }: deedOrGiftProps) {
    return (
        <p
            style={
                formType === "Gift in Kind"
                    ? {
                          fontSize: "0.85rem",
                          paddingBottom: "0",
                          marginBottom: "0 !important",
                      }
                    : {}
            }
        >
            If there is a Deed of Gift or other original documents to include,
            please print this transmittal and mail the original documents with
            the transmittal to:
        </p>
    );
}
