import { Col, Row } from "react-bootstrap";
import ButtonMailto from "./ButtonMailTo";
import UrbanaAddress from "./UrbanaAddress";
import DeedOrGift from "./DeedOrGift";

type GiftInKindEmailMessageProps = {
    formType: string;
};

export default function GiftInKindEmailMessage({
    formType,
}: GiftInKindEmailMessageProps) {
    return (
        <>
            <Col>
                <p>
                    Email this transmittal and all scanned documentation, as a
                    pdf, to Gift Administration:{" "}
                    <ButtonMailto
                        label="giftadmin@uif.uillinois.edu."
                        mailto="giftadmin@uif.uillinois.edu"
                    />{" "}
                    &nbsp;
                </p>
            </Col>
            {formType === "Gift in Kind" && (
                <>
                    {/* <Col>
                        <DeedOrGift formType={formType} />
                    </Col> */}
                    <Col>
                        <>
                            <Row>
                                <UrbanaAddress />
                            </Row>
                        </>
                    </Col>
                </>
            )}
        </>
    );
}
