import {
    Button,
    Container,
    // createTheme,
    // ThemeProvider,
    Typography,
} from "@mui/material";
import { useReactToPrint } from "react-to-print";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import {  createRef } from "react";
import PrintTribute from "./PrintTribute";
import PrintTotals from "./PrintTotals";

import PrintSecurities from "./PrintSecurities";
import PrintDesignations from "./PrintDesignations";
import PrintDonorInformation from "./PrintDonorInformation";
import PrintCampusAddressSwapNoHeaderAddress from "../PrintCampusAddressSwapNoHeaderAddress";
import PrintUIFOnlyContactInformation from "./PrintUIFOnlyContactInformation";

type PrintParams = {
    formValues: any;
    returnUrl: string;
    //  title: string;
    printSecurities: boolean;
};

const SecWirePrint = ({
    formValues,
    returnUrl,
    // title,
    printSecurities,
}: PrintParams) => {
    const navigate = useNavigate();
    // const componentRef = useRef();
    const componentRef = createRef<HTMLDivElement>();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    // const theme = createTheme({
    //     typography: {
    //         formCat: {
    //             fontWeight: "bold",
    //         },
    //     },
    // });

    return (
        <>
            {/* <ThemeProvider theme={theme}> */}
            <div ref={componentRef}>
                <Container>
                    <div id="timestamp"></div>
                    <PrintCampusAddressSwapNoHeaderAddress
                        campusLocation={formValues.campusLocation}
                    />
                    <Container>
                        <Row className="hideForPrint">
                            <Col className="d-flex justify-content-around">
                                <Button
                                    variant="contained"
                                    className="buttonClass"
                                    onClick={() => {
                                        navigate(returnUrl);
                                    }}
                                >
                                    Back
                                </Button>
                            </Col>
                            <Col className="d-flex justify-content-around">
                                <Button
                                    variant="contained"
                                    onClick={handlePrint}
                                >
                                    Print Document
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                    <PrintUIFOnlyContactInformation formValues={formValues} />

                    <PrintDonorInformation formValues={formValues} />
                    <PrintTotals formValues={formValues} />
                    <PrintTribute formValues={formValues} />

                    {printSecurities ? (
                        <>
                            <PrintSecurities formValues={formValues} />
                        </>
                    ) : (
                        <></>
                    )}

                    <PrintDesignations formValues={formValues} />
                    {formValues.generalComments ? (
                        <>
                            <Row>
                                <Col>
                                    <Typography
                                        variant="h5"
                                        component="h3"
                                        className="mt-2 mr-0 mb-3 ml-0"
                                    >
                                        General Comments
                                    </Typography>

                                    <Col
                                        className="border"
                                        style={{ padding: "1em" }}
                                    >
                                        {formValues.generalComments}
                                    </Col>
                                </Col>
                            </Row>
                        </>
                    ) : (
                        <></>
                    )}
                    <PrintTotals formValues={formValues} />
                    <Container>
                        <Row className="hiddenForPrint">
                            <Col className="d-flex col justify-content-center">
                                <Button
                                    variant="contained"
                                    className="buttonClass"
                                    onClick={() => {
                                        navigate(returnUrl);
                                    }}
                                >
                                    Back
                                </Button>
                            </Col>
                            <Col className="d-flex col justify-content-center">
                                <Button
                                    variant="contained"
                                    onClick={handlePrint}
                                >
                                    Print Document
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </div>
            {/* </ThemeProvider> */}
        </>
    );
};

export default SecWirePrint;
