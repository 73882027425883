import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import React from "react";
import { Col, Row } from "react-bootstrap";
import PrintOneGIK from "./PrintOneGIK";

type PrintParams = {
    formValues: any;
};

const PrintGIKs = ({ formValues }: PrintParams) => {
    var giftInKindCount = 0;
    if (formValues.giftInKind !== undefined)
        giftInKindCount = formValues.giftInKind.length; // used to determine if we should show Delete GIK buttons
    return giftInKindCount > 0 ? (
        <div>
            <Row>
                <Col>
                    <Typography variant="h5" component="h3" className="mt-2">
                        Gift In Kind Information
                    </Typography>
                </Col>
            </Row>
            <div className="printTable mt-3 pb-3 border">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Description</TableCell>
                            <TableCell>Gift Value</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {formValues.giftInKind.map(
                            (item: any, index: number) => {
                                return (
                                    <React.Fragment key={"oneGik" + index}>
                                        <PrintOneGIK
                                            oneGiftInKind={item}
                                            index={index}
                                        />
                                    </React.Fragment>
                                );
                            }
                        )}
                    </TableBody>
                </Table>
            </div>
        </div>
    ) : null;
};

export default PrintGIKs;
