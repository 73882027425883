import { useLocation } from "react-router-dom";

export default function SplitLocation() {
    //assigning location variable
    const location = useLocation();

    //destructuring pathname from location
    const { pathname } = location;

    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");
    return (
        <>
            <div className="navLinks">
                <div className={splitLocation[1] === "cash" ? "active" : ""}>
                    <h1>Cash Gift Transmittal Form</h1>
                </div>

                <div
                    className={splitLocation[1] === "cashprint" ? "active" : ""}
                >
                    <h1>Cash Gift Transmittal</h1>
                </div>

                <div className={splitLocation[1] === "check" ? "active" : ""}>
                    <h1>Check Gift Transmittal Form</h1>
                </div>

                <div
                    className={
                        splitLocation[1] === "checkprint" ? "active" : ""
                    }
                >
                    <h1>Check Gift Transmittal</h1>
                </div>

                <div
                    className={
                        splitLocation[1] === "giftinkind" ? "active" : ""
                    }
                >
                    <h1>Gift in Kind Transmittal Form</h1>
                </div>

                <div
                    className={
                        splitLocation[1] === "giftinkindprint" ? "active" : ""
                    }
                >
                    <h1>Gift in Kind Transmittal</h1>
                </div>

                <div
                    className={
                        splitLocation[1] === "securities" ? "active" : ""
                    }
                >
                    <h1>
                        Securities Gift Transmittal Form{" "}
                        <span>*(UIF Only)</span>
                    </h1>
                </div>

                <div
                    className={
                        splitLocation[1] === "securitiesprint" ? "active" : ""
                    }
                >
                    <h1>
                        Securities Gift Transmittal <span>*(UIF Only)</span>
                    </h1>
                </div>

                <div className={splitLocation[1] === "wire" ? "active" : ""}>
                    <h1>
                        Wire Transmittal Form <span>*(UIF Only)</span>
                    </h1>
                </div>

                <div
                    className={splitLocation[1] === "wireprint" ? "active" : ""}
                >
                    <h1>
                        Wire Transmittal <span>*(UIF Only)</span>
                    </h1>
                </div>

                <div className={splitLocation[1] === "trust" ? "active" : ""}>
                    <h1>Trust Gift Transmittal Form</h1>
                </div>

                <div
                    className={
                        splitLocation[1] === "trustprint" ? "active" : ""
                    }
                >
                    <h1>Trust Gift Transmittal</h1>
                </div>

                <div
                    className={
                        splitLocation[1] === "trustdeferred" ? "active" : ""
                    }
                >
                    <h1>Trust Deferred Gift Transmittal Form</h1>
                </div>

                <div
                    className={
                        splitLocation[1] === "trustdeferredprint"
                            ? "active"
                            : ""
                    }
                >
                    <h1>Trust Deferred Gift Transmittal</h1>
                </div>
            </div>
        </>
    );
}