import * as React from "react";
import { useFormContext } from "react-hook-form";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { styled } from "@mui/system";

type StyledTextareaParams = {
    placeholder: string;
    className: string;
    required?: boolean;
    name: string;
    "aria-label"?: string;
};

const StyledTextarea = React.forwardRef<
    HTMLTextAreaElement,
    StyledTextareaParams
>(
    (
        { placeholder, name, required, className, "aria-label": ariaLabel },
        ref
    ) => {
        const {
            register,
            formState: { errors },
        } = useFormContext();

        return (
            <>
                <TextareaAutosize
                    minRows={1.5}
                    {...register(name, { required })}
                    placeholder={placeholder}
                    className={className}
                    aria-label={ariaLabel}
                />
                {errors[name] && <span>This field is required</span>}
            </>
        );
    }
);

export default StyledTextarea;

const grey = {
    400: "#B0B8C4",
};

const TextareaAutosize = styled(BaseTextareaAutosize)(
    ({ theme }) => `
  width: 100%;
//   height: 100% !important;
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid ${grey[400]};
`
);
