import { ReactNode } from "react";
import { Typography } from "@mui/material";

type addrHeader = {
    headerLine: ReactNode;
};

export default function ChicagoAddress({ headerLine = "" }: addrHeader) {
    return (
        <Typography variant="subtitle2" component="div" className="mb-2">
            {headerLine}
            University of Illinois Chicago - OVCA
            <br />
            SCE 750 S Halsted St. Rm. 550, M/C 100
            <br />
            Chicago, IL 60607
        </Typography>
    );
}
