import { TableCell, TableRow } from "@mui/material";
import { formatAmount } from "../HelperFunctions";

type PrintParams = {
    oneGiftInKind: any;
    index: number;
};

export default function PrintOneGIK({ oneGiftInKind, index }: PrintParams) {
    return (
        <>
            <TableRow key={"giftInKind" + index}>
                <TableCell
                    component="th"
                    scope="row"
                    style={{
                        width: "50%",
                        fontWeight: "bold",
                        fontSize: "1rem",
                    }}
                >
                    <div style={{ textAlign: "left" }}>
                        {oneGiftInKind.descriptionOfGift}
                    </div>
                </TableCell>
                <TableCell style={{ width: "123px" }}>
                    {formatAmount(oneGiftInKind.giftValue)}
                </TableCell>
            </TableRow>
        </>
    );
}
